/* eslint-env jquery */
/* eslint-disable no-unused-vars */
const getAnchorHash = (function getAnchorHashIIFE() {
  const anchor = document.getElementsByTagName('a');

  for (let i = 0; i < anchor.length; i += 1) {
    const anchorHash = anchor[i].href.split('#')[0];
    const currentUrl = window.location.href.split('#')[0];

    if (anchor[i].hash.substr(1) !== '' && anchorHash === currentUrl) {
      anchor[i].addEventListener('click', (event) => {
        event.preventDefault();

        jQuery('html, body').animate({

          scrollTop: jQuery(event.currentTarget.hash).offset().top - 250,
        }, 1000);
      });
    }
  }
}());

jQuery('.accordion-tab').on('click', function accordionIIFE() {
  if ((jQuery(this)[0]).isEqualNode(jQuery('.accordion-tab-open')[0]) === false) {
    jQuery('.accordion-tab-open').next().slideToggle('slow');
    jQuery('.accordion-tab-open').attr('class', 'accordion-tab');
  }

  jQuery(this).next().slideToggle('slow');
  jQuery(this).toggleClass('accordion-tab-open');
});


const navMobile = (function navMobileIIFE() {
  const headerToggle = document.getElementsByClassName('header-toggle')[0];
  const headerToggleIcon = document.getElementsByClassName('header-toggle-icon')[0];
  headerToggle.addEventListener('click', (event) => {
    headerToggleIcon.classList.toggle('header-toggle-icon-open');
    jQuery('.nav-mobile').slideToggle('slow');
  });
}());
